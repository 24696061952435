
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useGetOperatorIdentifierBySkalNumberQuery } from '@/models'
import { useCurrentOperatorIdentifier } from '@/hooks/UseCurrentOperatorIdentifier'
import { useCurrentOperatorSkalNumber } from '@/hooks/UseCurrentOperatorSkalNumber'
import { multiLanguageRoutes } from '@/router/routes'

function LegacyLandingPage (): JSX.Element {
  const [searchParams] = useSearchParams()
  const [, setCurrentOperatorIdentifier] = useCurrentOperatorIdentifier()
  const [, setCurrentOperatorSkalNumber] = useCurrentOperatorSkalNumber()
  const [isCurrentOperatorSet, setIsCurrentOperatorSet] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const skalNumberQueryParameter = searchParams.get('skalNumber')
  const redirectUriQueryParameter = searchParams.get('redirectUri')

  if (skalNumberQueryParameter === null || skalNumberQueryParameter.length <= 0) {
    throw new Error('Missing required parameter skalNumber')
  }
  const { data } = useGetOperatorIdentifierBySkalNumberQuery({ variables: { skalNumber: skalNumberQueryParameter } })

  useEffect(() => {
    if (data !== undefined) {
      if (data.operator.length > 0) {
        setCurrentOperatorIdentifier(data.operator[0].id)
        setCurrentOperatorSkalNumber(skalNumberQueryParameter)
        setIsCurrentOperatorSet(true)
        return
      }

      navigateToNotFound()
    }
  }, [data])

  useEffect(() => {
    if (isCurrentOperatorSet) {
      if (redirectUriQueryParameter === null || redirectUriQueryParameter.length <= 0) {
        throw new Error('Missing required parameter redirectUri')
      }
      const redirectUri = new URL(decodeURIComponent(redirectUriQueryParameter))
      navigate(redirectUri.pathname)
    }
  }, [isCurrentOperatorSet])

  const navigateToNotFound = (): void => {
    const languageObjectKey = Object.keys(multiLanguageRoutes.legacy.paths)[Object.values(multiLanguageRoutes.legacy.paths).indexOf(location.pathname)]
    // @ts-expect-error
    const destination = multiLanguageRoutes.notFound.paths[languageObjectKey]
    navigate(destination)
  }

  return (
    <div></div>
  )
}

export default LegacyLandingPage
