import '@/features/errorModal/ErrorModal.less'
import Modal from 'antd/lib/modal/Modal'
import { ReactNode, useState } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import { Button } from 'antd'
import { Trans } from 'react-i18next'

interface ErrorModalProps {
  messageContent?: ReactNode | ReactNode[]
}

function ErrorModal ({ messageContent }: Readonly<ErrorModalProps>): JSX.Element {
  const [isOpen, setIsOpen] = useState(true)

  const onCloseClick = (): void => {
    setIsOpen(false)
  }

  return (
    <Modal className='error-modal' open={isOpen} onCancel={onCloseClick} destroyOnClose footer={null} maskClosable data-testid='error-modal'>
        <div className='error-modal__body'>
          <div className='error-modal__body__icon'>
            <ExclamationCircleOutlined />
          </div>
          <div className="error-modal__body__text">
            <h3>{t('errorModal.title')}</h3>
            <p>
              {messageContent === undefined
                ? <span data-testid='default-message'><Trans i18nKey={'errorModal.defaultErrorMessage'} components={{ aTag: <a href={`mailto:${process.env.REACT_APP_SKAL_SUPPORT_EMAIL as string}`} /> }} values={{ email: process.env.REACT_APP_SKAL_SUPPORT_EMAIL as string, phone: process.env.REACT_APP_SKAL_SUPPORT_PHONE as string }}></Trans></span>
                : <span data-testid='custom-message'>{messageContent}</span>
              }
              </p>
          </div>
        </div>
        <Button className='error-modal__button' type='primary' onClick={onCloseClick}>{t('errorModal.closeButton')}</Button>
    </Modal>
  )
}

export default ErrorModal
