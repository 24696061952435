import '@/features/certificate/inquiry/components/certificateInquiryRequestingTable/CertificateInquiryRequestingTable.less'
import { useTranslation } from 'react-i18next'
import { Empty, Table, Input, Space } from 'antd'
import type { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface'
import type { TablePaginationConfig } from 'antd/es/table'
import { GetRequestingCertificatesDocument, OrderBy, useGetRequestingCertificatesQuery, useRemoveCertificateInquiryMutation } from '@/models/generated/graphql'
import { useState, useEffect, ChangeEvent } from 'react'
import DataRow from '@/features/certificate/inquiry/components/certificateInquiryRequestingTable/models/CertificateInquiryRequestingTableDataRow'
import mapRequestingCertificatesQuery from '@/features/certificate/inquiry/components/certificateInquiryRequestingTable/CertificateInquiryRequestingTableMapper'
import { SearchOutlined } from '@ant-design/icons'
import { useDebounce } from '@/hooks/UseDebounce'
import { getTableColumns } from '@/features/certificate/inquiry/components/certificateInquiryRequestingTable/models/CertificateInquiryRequestingTableColumns'
import AddSupplierModal from '@/features/certificate/inquiry/components/addSupplierModal/AddOperatorModal'
import { debouncedTime } from '@/index'
import { useCurrentOperatorIdentifier } from '@/hooks/UseCurrentOperatorIdentifier'
import { useErrorModal } from '@/features/errorModal/ErrorModalContextProvider'

function CertificateInquiryRequestingTable (): JSX.Element {
  const { t } = useTranslation()
  const { showError } = useErrorModal()
  const [totalCount, setTotalCount] = useState(-1)
  const [pageSize] = useState(10)
  const [debouncedSearch, search, setSearch] = useDebounce('', debouncedTime)
  const [offset, setOffset] = useState(0)
  const [orderBy, setOrderBy] = useState({
    name: OrderBy.Asc,
    requested_operator_skal_number: OrderBy.Asc
  })
  const [processingActions, setProcessingActions] = useState(false)
  const [currentOperatorIdentifier] = useCurrentOperatorIdentifier()

  const { loading, error, data, refetch } = useGetRequestingCertificatesQuery({
    variables: {
      requesting_operator_id: currentOperatorIdentifier,
      limit: pageSize,
      search: `%${debouncedSearch}%`,
      order_by_name: orderBy.name,
      order_by_skalNumber: orderBy.requested_operator_skal_number,
      offset
    }
  })

  if (error !== undefined) {
    console.error(error)
  }

  useEffect(() => {
    if (data !== undefined) {
      setTotalCount(data.operator_shared_certificate_aggregate.aggregate?.count ?? 0)
    }
  }, [data])

  useEffect(() => {
    refetch({
      requesting_operator_id: currentOperatorIdentifier,
      limit: pageSize,
      search: `%${debouncedSearch}%`,
      order_by_name: orderBy.name,
      order_by_skalNumber: orderBy.requested_operator_skal_number,
      offset
    }).catch(x => {
      console.error(x)
      showError()
    })
  }, [offset])

  if (totalCount === -1 && data !== undefined) {
    setTotalCount(data.operator_shared_certificate_aggregate.aggregate?.count ?? 0)
  }
  // If "refetchQueries" will cause performance issues, we can consider to use to update cache directly: https://www.apollographql.com/docs/react/data/mutations/#updating-the-cache-directly
  const [removeRequestingCertificateMutation] = useRemoveCertificateInquiryMutation({
    onCompleted: () => {
      // Check if the offset should be moved to the previous page
      if (offset > 0 && totalCount % pageSize === 1) {
        setOffset(offset - pageSize)
      }
    },
    refetchQueries: [GetRequestingCertificatesDocument]
  })

  const removeRequestingCertificate = (id: string): void => {
    if (processingActions) { return }

    setProcessingActions(true)
    removeRequestingCertificateMutation({ variables: { id } })
      .then(() => { setProcessingActions(false) })
      .catch((x: any) => {
        console.error(x)
        showError()
        setProcessingActions(false)
      })
  }

  const tableColumns = getTableColumns({
    handleOnClickCancelAcceptAction: removeRequestingCertificate
  })
  const dataSource = data != null ? mapRequestingCertificatesQuery(data) : []

  const handleTableChange = (_pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: SorterResult<DataRow> | Array<SorterResult<DataRow>>, _extra: TableCurrentDataSource<DataRow>): void => {
    const sorterArray = Array.isArray(sorter) ? sorter : [sorter]
    const orderByDict: { [name: string]: OrderBy } = {}
    for (const sort of Array.isArray(sorter) ? sorter : [sorter]) {
      if (sort.columnKey !== undefined) {
        orderByDict[sort.columnKey] = sort.order === 'ascend' ? OrderBy.Asc : OrderBy.Desc
      }
    }

    if (sorterArray.some(x => x.column !== undefined)) {
      setOrderBy({ requested_operator_skal_number: orderByDict.skalNumber, name: orderByDict.name })
    }
    setOffset(((_pagination.current ?? 1) - 1) * pageSize)
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setOffset(0)
    setSearch(e.target.value)
  }

  return (
    <Space direction='vertical' className='full-width'>
      <div className="table-filters">
        <Input className='certificates-inquiry-requesting-table-search' data-testid="certificates-inquiry-requesting-table-search"
          value={search}
          onChange={ handleSearchChange }
          placeholder={t('certificates.inquiry.table.search').toString()}
          allowClear
          addonAfter={<SearchOutlined />}
        />
        <AddSupplierModal/>
      </div>
      <Table className='certificate-inquiry-requesting-table' data-testid="certificates-inquiry-requesting-table"
        loading={loading || processingActions}
        dataSource={dataSource}
        columns={tableColumns}
        pagination={{
          position: ['bottomCenter'],
          pageSize,
          total: totalCount,
          current: (Math.floor(offset / pageSize)) + 1,
          showSizeChanger: false,
          size: 'small'
        }}
        onChange={handleTableChange}
        locale={{
          emptyText: <Empty description={t('certificates.inquiry.table.emptyUsingTableText')} />,
          triggerAsc: t('certificates.inquiry.table.ascSort').toString(),
          triggerDesc: t('certificates.inquiry.table.descSort').toString(),
          cancelSort: t('certificates.inquiry.table.cancelSort').toString()
        }}
        scroll={{ x: 1190 }}
      />
    </Space>
  )
}

export default CertificateInquiryRequestingTable
