const DEFAULT_NUM_TRIES = 3
const RETRY_DELAY = 100

async function delay (): Promise<void> {
  return await new Promise(resolve => {
    setTimeout(() => { resolve() }, RETRY_DELAY)
  })
}

export async function fetchWithRetry (url: string, retries: number = DEFAULT_NUM_TRIES): Promise<Response> {
  try {
    const response = await fetch(url)

    if (response.ok) {
      return response
    }

    throw new Error(`${response.statusText} (${response.status})`)
  } catch (err: any) {
    if (retries > 1) {
      console.error(`Request failed due to ${String(err)} ${url}. Will retry ${retries - 1} more after ${RETRY_DELAY}ms`)
      await delay()
      return await fetchWithRetry(url, retries - 1)
    }

    throw new Error(`Request failed due to ${String(err)} ${url}. Aborted due to maximum number of retries`)
  }
}
