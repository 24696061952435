import { t } from 'i18next'
import { Location } from '@/models/generated/graphql'
import ShareCertificateTableActions from './ShareCertificateTableActions'
import ShareCertificateTableDataRow from './ShareCertificateTableDataRow'
import CertificateInquiryStatus from '@/models/enums/CertificateInquiryStatus'

const action = (actions: ShareCertificateTableActions, row: ShareCertificateTableDataRow): JSX.Element => {
  if (row.actions === CertificateInquiryStatus.Accepted) {
    return (<a onClick={() => actions.handleOnClickCancelShareAction(row.id)}>{t('certificates.inquiry.shareCertificate.table.data.actions.cancelShare')}</a>)
  } else {
    return (<a onClick={() => actions.handleOnClickShareAction(row.requestingOperatorId)}>{t('certificates.inquiry.shareCertificate.table.data.actions.share')}</a>)
  }
}

export const getShareCertificateTableColumns = (actions: ShareCertificateTableActions): any => {
  return [
    {
      key: 'name',
      title: t('certificates.inquiry.shareCertificate.table.columns.name'),
      dataIndex: 'name',
      render: (x: string) => x,
      width: 285,
      ellipsis: true,
      sorter: true
    }, {
      key: 'skalNumber',
      title: t('certificates.inquiry.shareCertificate.table.columns.skalNumber'),
      dataIndex: 'skalNumber',
      width: 135,
      sorter: true
    }, {
      key: 'primaryLocation',
      title: t('certificates.inquiry.shareCertificate.table.columns.primaryLocation'),
      dataIndex: 'primaryLocation',
      render: (x: Location) => `${x?.street ?? ''} ${x?.houseNumber ?? ''}, ${x?.postalCode as string ?? ''}, ${x?.city ?? ''}`,
      width: 200,
      ellipsis: { showTitle: true }
    }, {
      key: 'actions',
      title: t('certificates.inquiry.shareCertificate.table.columns.actions'),
      dataIndex: 'actions',
      render: (x: CertificateInquiryStatus, row: ShareCertificateTableDataRow) => action(actions, row),
      width: 200
    }
  ]
}
