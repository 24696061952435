import { parseStringToCertificateInquiryStatus } from '@/models/enums/CertificateInquiryStatus'
import { GetRequestedCertificatesQuery, Location } from '@/models/generated/graphql'
import DataRow from '@/features/certificate/inquiry/components/certificateInquiryRequestedTable/models/CertificateInquiryRequestedTableDataRow'

const mapRequestedCertificatesQuery = (data: GetRequestedCertificatesQuery): DataRow[] => {
  return data.operator_shared_certificate.flatMap(x => ({
    id: x.operator_certificate_inquiry_id,
    key: `${x.requesting_operator_id as string}, ${x.requested_operator_id as string}, ${x.certificate_number as string}`,
    status: parseStringToCertificateInquiryStatus(x.status ?? ''),
    name: x.requesting_operator_location_name ?? '',
    skalNumber: x.requesting_operator_skal_number ?? '',
    primaryLocation: { // eslint-disable-line
      name: x.requesting_operator_location_name,
      street: x.requesting_operator_location_street,
      houseNumber: x.requesting_operator_location_house_number,
      city: x.requesting_operator_location_city,
      postalCode: x.requesting_operator_location_postal_code
    } as Location,
    sharedSince: x.accepted_at,
    actions: parseStringToCertificateInquiryStatus(x.status ?? '')
  }))
}

export default mapRequestedCertificatesQuery
