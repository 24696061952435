import '@/features/certificate/inquiry/CertificateInquiry.less'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'
import CertificateInquiryRequestingTable from '@/features/certificate/inquiry/components/certificateInquiryRequestingTable/CertificateInquiryRequestingTable'
import CertificateInquiryTabs from '@/features/certificate/inquiry/components/certificateInquiryTabs/CertificateInquiryTabs'
import CertificateInquiryRequestedTable from '@/features/certificate/inquiry/components/certificateInquiryRequestedTable/CertificateInquiryRequestedTable'
import { useState } from 'react'
import { useCurrentOperatorSkalNumber } from '@/hooks/UseCurrentOperatorSkalNumber'
import { useGetActiveCertificateBySkalNumberQuery } from '@/models'
import { LoadingOutlined } from '@ant-design/icons'

function CertificateInquiry (): JSX.Element {
  const { t } = useTranslation()
  const [currentOperatorSkalNumber] = useCurrentOperatorSkalNumber()
  const { loading, data } = useGetActiveCertificateBySkalNumberQuery({ variables: { skalNumber: currentOperatorSkalNumber } })

  const [containerIndex, setContainerIndex] = useState(0)

  const handleOnClickRequesting = (): void => { setContainerIndex(0) }
  const handleOnClickRequested = (): void => { setContainerIndex(1) }

  if (loading) {
    return (<div className="message loader" data-testid="active-certificate-loading"><LoadingOutlined /></div>)
  }

  return (
    <div className='certificate-inquiry-container' data-testid="shared-certificates">
      <Col>
        <h1>{t('certificates.inquiry.title')}</h1>
      </Col>
      <Col>
        <div className='certificate-inquiry-tabs'>
            <CertificateInquiryTabs onClickRequesting={handleOnClickRequesting} onClickRequested={handleOnClickRequested} hasActiveCertificate={data != null && data.certificate.length !== 0} />
        </div>
      </Col>
      <Col>
        {containerIndex === 1 ? <CertificateInquiryRequestedTable/> : <CertificateInquiryRequestingTable />}
      </Col>
    </div>
  )
}

export default CertificateInquiry
