import { useState, useEffect } from 'react'

export function useDebounce<T> (initialValue: T, timeInMiliseconds: number): [T, T, React.Dispatch<T>] {
  const [value, setValue] = useState<T>(initialValue)
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedValue(value)
    }, timeInMiliseconds)
    return () => {
      clearTimeout(debounce)
    }
  }, [value, timeInMiliseconds])

  return [debouncedValue, value, setValue]
}
