/* eslint-disable @typescript-eslint/no-non-null-assertion */
import '@/features/certificate/detail/CertificateDetail.less'
import { useGetActiveCertificateBySkalNumberQuery, useGetTracesCertificateMutation } from '@/models/generated/graphql'
import { Trans, useTranslation } from 'react-i18next'
import { Row, Col, Empty } from 'antd'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import { parseDateToString } from '@/utils/dateUtils'
import { useCurrentOperatorSkalNumber } from '@/hooks/UseCurrentOperatorSkalNumber'
import { useState } from 'react'
import { useErrorModal } from '@/features/errorModal/ErrorModalContextProvider'
import CertificateDetailHeader from './components/CertificateDetailHeader/CertificateDetailHeader'
import CertificateDetailAlert from './components/CertificateDetailAlert/CertificateDetailAlert'

function CertificateDetail (): JSX.Element {
  const { t } = useTranslation()
  const { showError } = useErrorModal()
  let { skalNumber } = useParams()
  const [currentOperatorSkalNumber] = useCurrentOperatorSkalNumber()
  const isSharedCertificate = skalNumber !== undefined
  document.title = isSharedCertificate ? t('pageTitles.sharedCertificate') : t('pageTitles.ownCertificate')

  if (!isSharedCertificate) {
    skalNumber = currentOperatorSkalNumber
  }

  const { loading, error, data } = useGetActiveCertificateBySkalNumberQuery({ variables: { skalNumber: skalNumber ?? '' } })
  const [isDownloadingTracesCertificate, setIsDownloadingTracesCertificate] = useState(false)
  const [getTracesCertificateMutation] = useGetTracesCertificateMutation()

  const alignDetailsToTheLeft = useMediaQuery({ maxWidth: 576 })
  const alignLeftClassName = alignDetailsToTheLeft ? 'align-left' : ''

  if (loading) {
    return (<div className="message loader" data-testid="my-skal-certificates-loading"><LoadingOutlined /></div>)
  }
  if (error != null) {
    return (
      <div className="certificate-detail" data-testid="my-skal-certificates">
        <CertificateDetailHeader isSharedCertificate={isSharedCertificate} />
        <CertificateDetailAlert data-testid='certificate-detail-alert' />
      </div>)
  }
  if (!data?.certificate?.length) { // eslint-disable-line @typescript-eslint/strict-boolean-expressions
    return (<Empty className="message" image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('certificates.errorMessage.noActiveCertificate')} data-testid="my-skal-certificates-empty" />)
  }

  const activeCertificate = data.certificate[0]
  const primaryLocation = activeCertificate.operator.locations.find(x => x.isPrimary)

  const onDownloadClick = (): void => {
    if (!isDownloadingTracesCertificate) {
      setIsDownloadingTracesCertificate(true)
      getTracesCertificateMutation({ variables: { certificateId: activeCertificate.id } }).then((result) => {
        const base64: string | undefined = result.data?.getTracesCertificate?.base64EncodedTracesCertificate
        const a = document.createElement('a')
        a.href = `data:text/pdf;base64,${base64!}`
        a.download = `${activeCertificate.tracesNumber}.pdf`
        a.click()
      })
        .catch((error: Error) => {
          console.error(error)
          const supportEmail = process.env.REACT_APP_SKAL_SUPPORT_EMAIL as string
          showError({ messageContent: <Trans i18nKey='errorModal.certificateDownloadError' components={{ aTag: <a href={`mailto:${supportEmail}`} /> }} values={{ email: supportEmail, phone: process.env.REACT_APP_SKAL_SUPPORT_PHONE as string }}></Trans> })
        })
        .finally(() => {
          setIsDownloadingTracesCertificate(false)
        })
    }
  }

  return (
    <div className="certificate-detail" data-testid="my-skal-certificates">
      <CertificateDetailHeader isSharedCertificate={isSharedCertificate} />
      <Row gutter={24} justify="center">
        <div className="bio-certificate">
          <div className="bio-certificate__info">
            <Row>
              <Col sm={12} xs={24}><h6 className="bio-certificate__info__name">{primaryLocation?.name}</h6></Col>
              <Col sm={12} xs={24} className={`bio-certificate__info__skal-number bio-certificate__info__skal-number--${alignLeftClassName}`}>{t('certificates.skalNumber')}: {skalNumber}</Col>
            </Row>
            <Row><p className="bio-certificate__info__tradeNames">{primaryLocation?.tradeName}</p></Row>
            <Row className="bio-certificate__info__address">{primaryLocation?.street} {primaryLocation?.houseNumber}</Row>
            <Row className="bio-certificate__info__postalCode">{primaryLocation?.postalCode} {primaryLocation?.city}, {primaryLocation?.country}</Row>
          </div>
          <Row className={`bio-certificate__footer bio-certificate__footer--${alignLeftClassName}`} justify="space-between" align="middle">
            <Col>
                <div data-testid="traces-download-button" className={`certificate-detail__download-button ${isDownloadingTracesCertificate ? 'disabled' : ''}`} onClick={onDownloadClick}>
                  {isDownloadingTracesCertificate ? <LoadingOutlined /> : <DownloadOutlined />}
                  <span className="download-text">{t('certificates.download')}</span>
                </div>
            </Col>
            <Col className="bio-certificate__footer__dates">
              <Row><p className={`bio-certificate__footer__dates--valid-from bio-certificate__footer__dates--${alignLeftClassName}`}>{t('certificates.validFrom')} {parseDateToString(activeCertificate.validFrom)}</p></Row>
              <Row><p className={`bio-certificate__footer__dates--valid-until bio-certificate__footer__dates--${alignLeftClassName}`}>{t('certificates.validUntil')} {parseDateToString(activeCertificate.validUntil)}</p></Row>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default CertificateDetail
