import '@/features/footer/MySkalFooter.less'
import { Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { MailOutlined, InfoCircleOutlined } from '@ant-design/icons'

function MySkalFooter (): JSX.Element {
  const { t } = useTranslation()

  return (
    <div className="footer" data-testid="my-skal-footer">
      <Row justify="center">
        <div className="logo-container">
          <div className="logo"></div>
        </div>
      </Row>
      <Row justify="center">
        <div className="info">
          <div>
            <MailOutlined /><a href={`mailto: ${process.env.REACT_APP_SKAL_CONTACT_MAIL}`} target="_blank" rel="noreferrer">{t('footer.contact')}</a> {/* eslint-disable-line @typescript-eslint/restrict-template-expressions */}
          </div>
          <div>
            <InfoCircleOutlined /><a href={process.env.REACT_APP_SKAL_PRIVACY_STATEMENT_URL} target="_blank" rel="noreferrer">{t('footer.privacyStatement')}</a>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default MySkalFooter
