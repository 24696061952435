import { RouteObject } from 'react-router-dom'
import CertificateInquiry from '@/features/certificate/inquiry/CertificateInquiry'
import CertificateDetail from '@/features/certificate/detail/CertificateDetail'
import NotFound from '@/features/notFound/NotFound'
import LegacyLandingPage from '@/features/legacy/LegacyLandingPage'

export enum RouterNames {
  SharedCertificate,
  SharedCertificates,
  OwnCertificate,
  LegacyLanding,
  NotFound,
  LegacyLandingWithoutTranslation
}

export const multiLanguageRoutes = {
  sharedCertificates: {
    name: 'sharedCertificates',
    element: <CertificateInquiry />,
    paths: {
      nl: '/nl/certificaten/gedeeld',
      en: '/en/certificates/shared',
      de: '/de/zertifikate/geteilt'
    }
  },
  sharedCertificate: {
    name: 'sharedCertificate',
    element: <CertificateDetail />,
    paths: {
      nl: '/nl/certificaten/gedeeld/:skalNumber',
      en: '/en/certificates/shared/:skalNumber',
      de: '/de/zertifikate/geteilt/:skalNumber'
    }
  },
  ownCertificate: {
    name: 'ownCertificate',
    element: <CertificateDetail />,
    paths: {
      nl: '/nl/certificaten/mijn-certificaat',
      en: '/en/certificates/my-certificate',
      de: '/de/zertifikate/mein-zertifikat'
    }
  },
  legacy: {
    name: 'legacy',
    element: <LegacyLandingPage/>,
    paths: {
      nl: '/nl/legacy',
      en: '/en/legacy',
      de: '/de/legacy'
    }
  },
  notFound: {
    name: 'notFound',
    element: <NotFound />,
    paths: {
      nl: '/nl/niet-gevonden',
      en: '/en/not-found',
      de: '/de/nicht-gefunden'
    }
  }
}

export type routeKey = keyof typeof multiLanguageRoutes
export type pathKey = 'nl' | 'en' | 'de'

export const generateRoutes = (): RouteObject[] => {
  const generatedRoutes = Object.keys(multiLanguageRoutes).flatMap(routeName => {
    const routeObject = multiLanguageRoutes[routeName as routeKey]
    const route = Object.keys(routeObject.paths).map(path => {
      return {
        path: routeObject.paths[path as pathKey],
        element: routeObject.element
      }
    })
    return route
  })

  // add fallback route for 404 page
  generatedRoutes.push({
    path: '*',
    element: <NotFound />
  })

  return generatedRoutes
}
