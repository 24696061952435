import { useEffect, useState } from 'react'

export function useCurrentOperatorSkalNumber (): [string, React.Dispatch<string>] {
  const currentOperatorSkalNumberKey = 'skalNumber'

  const [currentOperatorSkalNumber, setCurrentOperatorSkalNumber] = useState<string>(() => {
    return localStorage.getItem(currentOperatorSkalNumberKey) ?? ''
  })

  useEffect(() => {
    if (currentOperatorSkalNumber === null) {
      throw new Error('Current operator skal number cannot be null')
    }
    localStorage.setItem(currentOperatorSkalNumberKey, currentOperatorSkalNumber)
  }, [currentOperatorSkalNumber])

  return [currentOperatorSkalNumber, setCurrentOperatorSkalNumber]
}
