import React, { ReactNode, useCallback, useState } from 'react'
import IErrorModal from './IErrorModal'

interface IErrorModalContext {
  errors: IErrorModal[]
  showError: (error?: IErrorModal) => void
}

interface IErrorModalContextProviderProps {
  children: ReactNode
}

const ErrorModalContext = React.createContext<IErrorModalContext>({
  errors: [],
  showError: () => { }
})

function ErrorModalContextProvider ({ children }: Readonly<IErrorModalContextProviderProps>): JSX.Element {
  const [errorModals, setErrorModals] = useState<IErrorModal[]>([])

  const showError = (error?: IErrorModal): void => {
    if (error === undefined) {
      error = {}
    }

    setErrorModals(errorModals.concat(error))
  }

  const contextValue = {
    errors: errorModals,
    showError: useCallback((error?: IErrorModal) => showError(error), [])
  }

  return (
    <ErrorModalContext.Provider value={contextValue}>
      {children}
    </ErrorModalContext.Provider>
  )
}

const useErrorModal = (): IErrorModalContext => React.useContext<IErrorModalContext>(ErrorModalContext)

export { ErrorModalContextProvider, useErrorModal }
