import '@/features/header/MySkalHeader.less'
import { Col, Row } from 'antd'
import MySkalNavigationMenu from '@/features/header/navigation/MySkalNavigation'
import MySkalProfileMenu from '@/features/header/profile/MySkalProfile'
import { useMediaQuery } from 'react-responsive'
import { getLegacyRedirectUrl } from '@/router/getLegacyRedirectUrl'

function MySkalHeader (): JSX.Element {
  const showLogo = useMediaQuery({ minWidth: 1000 })

  return (
    <div className="header" data-testid="my-skal-header">
      <Row justify="center">
        {showLogo &&
          <Col span="6">
            <a className="logo-container" href={getLegacyRedirectUrl()}>
              <div className="logo"></div>
            </a>
          </Col>
        }
        <Col span="12" offset={showLogo ? '0' : '2'}>
          <MySkalNavigationMenu />
        </Col>
        <Col span={ showLogo ? '6' : '10'} md="1" className='profile-container'>
          <MySkalProfileMenu />
        </Col>
      </Row>
    </div>
  )
}

export default MySkalHeader
