import IAuthenticationService from '@/features/authentication/IAuthenticationService'
import { ApolloClient, ApolloLink, DefaultOptions, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import IHasuraService from '@/services/IHasuraService'

export interface HasuraOptions {
  uri: string
  apolloOptions?: DefaultOptions
}

export default class HasuraService implements IHasuraService {
  private readonly _apolloClient: ApolloClient<NormalizedCacheObject>
  private readonly _authenticationService: IAuthenticationService

  get apolloClient (): ApolloClient<NormalizedCacheObject> {
    return this._apolloClient
  }

  constructor (hasuraOptions: HasuraOptions, authenticationService: IAuthenticationService) {
    this._authenticationService = authenticationService

    let httpLink: ApolloLink = new HttpLink({
      uri: hasuraOptions.uri
    })
    httpLink = this.addAuthenticationMiddleware(httpLink)

    this._apolloClient = new ApolloClient<NormalizedCacheObject>({ link: httpLink, cache: new InMemoryCache(), defaultOptions: hasuraOptions.apolloOptions })
  }

  private addAuthenticationMiddleware (link: ApolloLink): ApolloLink {
    return ApolloLink.from([this.authMiddleware, link])
  }

  private readonly authMiddleware = setContext(async (operation) => {
    await this._authenticationService.refreshTokenAsync()
    return {
      headers: {
        authorization: `Bearer ${this._authenticationService.token}`
      }
    }
  })
}
