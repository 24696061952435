import { parseStringToCertificateInquiryStatus } from '@/models/enums/CertificateInquiryStatus'
import { GetRequestingCertificatesQuery, Location } from '@/models/generated/graphql'
import DataRow from '@/features/certificate/inquiry/components/certificateInquiryRequestingTable/models/CertificateInquiryRequestingTableDataRow'

const mapRequestingCertificatesQuery = (data: GetRequestingCertificatesQuery): DataRow[] => {
  return data.operator_shared_certificate.flatMap(x => ({
    id: x.operator_certificate_inquiry_id,
    key: `${x.requesting_operator_id as string}, ${x.requested_operator_id as string}, ${x.certificate_number as string}`,
    status: parseStringToCertificateInquiryStatus(x.status ?? ''),
    name: x.requested_operator_location_name ?? '',
    skalNumber: x.requested_operator_skal_number ?? '',
    primaryLocation: {  // eslint-disable-line
      name: x.requested_operator_location_name,
      street: x.requested_operator_location_street,
      houseNumber: x.requested_operator_location_house_number,
      city: x.requested_operator_location_city,
      postalCode: x.requested_operator_location_postal_code
    } as Location,
    validUntil: x.valid_until,
    sharedSince: x.accepted_at,
    actions: parseStringToCertificateInquiryStatus(x.status ?? '')
  })) as DataRow[]
}

export default mapRequestingCertificatesQuery
