import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LocalazyBackend from '@/plugins/localazy-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { languageDectectorOptions } from './LanguageDectectorOptions'

const initI18n = async (metadataUrl: string): Promise<typeof i18n> => {
  const backend = new LocalazyBackend(undefined, { metadataUrl })

  await i18n
    .use(backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: ['nl', 'en', 'de'],
      supportedLngs: ['nl', 'en', 'de'],
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      detection: languageDectectorOptions
    })

  return i18n
}

export default initI18n
