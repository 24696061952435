import { Alert, Button } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import '@/features/certificate/detail/components/CertificateDetailAlert/CertificateDetailAlert.less'
import { useNavigate } from 'react-router-dom'

function CertificateDetailAlert (): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onRetryClick = (): void => {
    navigate(0)
  }

  return (
    <Alert
      data-testid='certificate-detail-alert'
      className='certificate-detail-alert'
      message={<div className='certificate-detail-alert__message'>
        <Trans i18nKey='certificates.errorMessage.pageLoadError' components={{ aTag: <a href={`mailto:${process.env.REACT_APP_SKAL_SUPPORT_EMAIL as string}`} /> }} values={{ email: process.env.REACT_APP_SKAL_SUPPORT_EMAIL as string, phone: process.env.REACT_APP_SKAL_SUPPORT_PHONE as string }}></Trans>
      </div>}
      type="error"
      showIcon
      action={
        <Button data-testid='certificate-detail-alert-button' className='certificate-detail-alert__button' onClick={onRetryClick} type='primary' >{t('certificates.errorMessage.retryButton')}</Button>
      }
    />
  )
}

export default CertificateDetailAlert
