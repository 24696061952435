import { LocalazyFormatted, LocalazyMetadata } from './localazy'
import { captureException } from '@sentry/react'
import { BackendModule, InitOptions, ReadCallback, Services } from 'i18next'
import { fetchWithRetry } from '@/utils/fetchWithRetry'
import fallbackDictionary from '@/locales/translation.json'

interface LocalazyBackendOptions {
  metadataUrl: string
}

class LocalazyBackend implements BackendModule<LocalazyBackendOptions> {
  private services?: Services

  private backendOptions?: LocalazyBackendOptions

  private i18nextOptions?: InitOptions<object>

  private metadata?: Promise<LocalazyFormatted>

  constructor (services?: Services, backendOptions?: LocalazyBackendOptions, i18nextOptions?: InitOptions<object>) {
    this.services = services
    this.backendOptions = backendOptions
    this.i18nextOptions = i18nextOptions
    this.type = 'backend'
  }

  init (services: Services, backendOptions: LocalazyBackendOptions, i18nextOptions: InitOptions<object>): void {
    this.services = {
      ...this.services,
      ...services
    }
    this.backendOptions = {
      ...this.backendOptions,
      ...backendOptions
    }
    this.i18nextOptions = {
      ...this.i18nextOptions,
      ...i18nextOptions
    }
  }

  read (language: string, namespace: string, callback: ReadCallback): void {
    void this.readTranslations(language, namespace, callback)
  }

  async readTranslations (language: string, namespace: string, callback: ReadCallback): Promise<void> {
    try {
      if (this.metadata == null) {
        this.metadata = this.loadMetadata()
      }

      await this.metadata.then(async (metadata) => {
        await this.loadTranslations(metadata, language, namespace, callback)
      })
    } catch (e) {
      captureException(e)
      // eslint-disable-next-line n/no-callback-literal, @typescript-eslint/restrict-template-expressions
      callback(`${e}`, null)
    }
  }

  async loadMetadata (): Promise<LocalazyFormatted> {
    try {
      const url: string = this.backendOptions?.metadataUrl ?? ''
      const response = await fetchWithRetry(url)

      const rootUrl = new URL(this.backendOptions?.metadataUrl ?? '')
      const metadataHost = `${rootUrl.protocol}//${rootUrl.host}`
      const data = await response.json() as LocalazyMetadata

      const namespaces = new Map(
        Object.values(data.files).map((file) => [
          file.file.replace('.json', ''),
          new Map(file.locales.map((locale) => [locale.language, `${metadataHost}${locale.uri}`]))
        ])
      )

      return {
        namespaces
      }
    } catch (error) {
      // return empty namespaces object if fetch is unsuccessful
      console.error('failed to load localazy metadata: ', error)
      return { namespaces: new Map() }
    }
  }

  async loadTranslations (metadata: LocalazyFormatted, language: string, namespace: string, callback: ReadCallback): Promise<void> {
    const url = metadata?.namespaces.get(namespace)?.get(language)
    let data

    if (url != null) {
      try {
        const response = await fetchWithRetry(url)
        data = await response.json()
      } catch (error) {
        console.error('failed to load languages, using fallback: ', error)
      }
    } else {
      console.error('Combination of namespace and language not found', namespace, language)
    }

    callback(null, data ?? fallbackDictionary)
  }

  type: 'backend'
}

export default LocalazyBackend
