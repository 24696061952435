enum CertificateInquiryStatus {
  Requested = 'Requested',
  Accepted = 'Accepted',
  Invalid = 'Invalid'
}

export default CertificateInquiryStatus

export function parseStringToCertificateInquiryStatus (value: string): CertificateInquiryStatus {
  switch (value) {
    case 'Requested': return CertificateInquiryStatus.Requested
    case 'Accepted': return CertificateInquiryStatus.Accepted
    default: return CertificateInquiryStatus.Invalid
  }
}
