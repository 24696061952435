import { Button, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'
import { multiLanguageRoutes, pathKey } from '@/router/routes'

function CertificateDetailHeader ({ isSharedCertificate }: Readonly<ICertificateDetailHeaderProps>): JSX.Element {
  const { t, i18n } = useTranslation()
  const hideBackButtonLabel = useMediaQuery({ maxWidth: 900 })

  return (
    <Row className="certificate-detail__header" justify="center" data-testid="certificate-detail-header">
      {isSharedCertificate &&
        <Link className="certificate-detail__back-button" to={multiLanguageRoutes.sharedCertificates.paths[i18n.language as pathKey]}>
          <Button type="text" icon={<ArrowLeftOutlined />} data-testid="certificate-back-button">{!hideBackButtonLabel && t('certificates.back')}</Button>
        </Link>
      }
      <h1 className="certificate-detail__title">{isSharedCertificate ? t('certificates.sharedCertificateTitle') : t('certificates.ownCertificateTitle')}</h1>
    </Row>)
}

interface ICertificateDetailHeaderProps {
  isSharedCertificate: boolean
}

export default CertificateDetailHeader
