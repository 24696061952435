import { GetRequestOperatorCertificateOptionsQuery, Location } from '@/models/generated/graphql'
import DataRow from '@/features/certificate/inquiry/components/addSupplierModal/models/AddOperatorTableDataRow'
import { parseStringToCertificateInquiryStatus } from '@/models/enums/CertificateInquiryStatus'

const mapInviteOperatorOptionsQuery = (data: GetRequestOperatorCertificateOptionsQuery): DataRow[] => {
  const inviteOperatorOptions = data.getRequestOperatorCertificateOptions?.requestOperatorCertificateOptions
  return inviteOperatorOptions?.flatMap(x => ({
    key: `${x?.name ?? ''}, ${x?.skalNumber ?? ''}, ${x?.operatorCertificateInquiryId ?? ''}`,
    id: x?.operatorCertificateInquiryId,
    name: x?.name ?? '',
    skalNumber: x?.skalNumber ?? '',
    requestedOperatorId: x?.operatorId ?? '',
    primaryLocation: { // eslint-disable-line
      name: x?.name,
      street: x?.address?.street,
      houseNumber: x?.address?.houseNumber,
      city: x?.address?.city,
      postalCode: x?.address?.postalCode
    } as Location,
    actions: parseStringToCertificateInquiryStatus(x?.status ?? '')
  })) as DataRow[]
}

export default mapInviteOperatorOptionsQuery
