import { Location } from '@/models/generated/graphql'
import CertificateInquiryStatus from '@/models/enums/CertificateInquiryStatus'
import { Link } from 'react-router-dom'
import DataRow from '@/features/certificate/inquiry/components/certificateInquiryRequestingTable/models/CertificateInquiryRequestingTableDataRow'
import { t } from 'i18next'
import Actions from './CertificateInquiryRequestingTableActions'

const status = (status: CertificateInquiryStatus): string => {
  if (status === CertificateInquiryStatus.Accepted) {
    return t('certificates.inquiry.table.data.status.accepted')
  } else {
    return t('certificates.inquiry.table.data.status.requested')
  }
}

const action = (actions: Actions, status: CertificateInquiryStatus, row: DataRow): JSX.Element => {
  const text = status === CertificateInquiryStatus.Accepted ? t('certificates.inquiry.table.data.actions.cancel') : t('certificates.inquiry.table.data.actions.cancelInvitation')
  return (<a data-testid="cancel" onClick={() => actions.handleOnClickCancelAcceptAction(row.id)}>{text}</a>)
}

export const getTableColumns = (actions: Actions): any => {
  return [
    {
      key: 'name',
      title: t('certificates.inquiry.table.columns.name'),
      dataIndex: 'name',
      render: (x: string, row: DataRow) => row.status === CertificateInquiryStatus.Accepted ? (<Link to={row.skalNumber} relative="path">{x ?? ''}</Link>) : x ?? '',
      width: 285,
      ellipsis: true,
      sorter: true
    }, {
      key: 'skalNumber',
      title: t('certificates.inquiry.table.columns.skalNumber'),
      dataIndex: 'skalNumber',
      width: 145,
      sorter: true
    }, {
      key: 'primaryLocation',
      title: t('certificates.inquiry.table.columns.primaryLocation'),
      dataIndex: 'primaryLocation',
      render: (x: Location) => `${x?.street ?? ''} ${x?.houseNumber ?? ''}, ${x?.postalCode as string ?? ''}, ${x?.city ?? ''}`,
      width: 200,
      ellipsis: { showTitle: true }
    }, {
      key: 'validUntil',
      title: t('certificates.inquiry.table.columns.validUntil'),
      dataIndex: 'validUntil',
      render: (x: string, row: DataRow) => row.status === CertificateInquiryStatus.Accepted ? new Date(x).toLocaleDateString() : '-',
      width: 130
    }, {
      key: 'sharedSince',
      title: t('certificates.inquiry.table.columns.sharedSince'),
      dataIndex: 'sharedSince',
      render: (x: string, row: DataRow) => row.status === CertificateInquiryStatus.Accepted ? new Date(x).toLocaleDateString() : '-',
      width: 135
    }, {
      key: 'status',
      title: t('certificates.inquiry.table.columns.status'),
      dataIndex: 'status',
      render: (x: CertificateInquiryStatus) => status(x),
      width: 120
    }, {
      key: 'actions',
      title: t('certificates.inquiry.table.columns.actions'),
      dataIndex: 'actions',
      render: (x: CertificateInquiryStatus, row: DataRow) => (<div>{ action(actions, x, row) }</div>),
      width: 180
    }
  ]
}
