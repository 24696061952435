import '@/index.less'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import App from '@/App'
import AuthenticationService from '@/features/authentication/AuthenticationService'
import HasuraService from '@/services/HasuraService'
import { generateRoutes } from '@/router/routes'
import * as Sentry from '@sentry/react'
import initI18n from './plugins/I18n'

// This file has been excluded from code coverage if more logic is added remove this exclusion from the sonar-project.properties file.

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    )
  })],
  // Sentry logs cancelled Fetch requests as errors, to prevent this we are ignoring these specific errors
  // See: https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios for more details
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: cancelled',
    'TypeError: NetworkError when attempting to fetch resource.'
  ],
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
})

export const debouncedTime = 300
export const authenticationService = new AuthenticationService()
export const hasuraService = new HasuraService({ uri: process.env.REACT_APP_HASURA_URL ?? '' }, authenticationService)

initI18n(process.env.REACT_APP_LOCALAZY_METADATA_URL as string).catch((err) => console.error(err))

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App authenticationService={authenticationService} hasuraService={hasuraService} />,
    children: [
      ...generateRoutes()
    ]
  }
])

authenticationService.authenticateAsync()
  .then(() => {
    const rootElement = document.getElementById('root')
    if (rootElement !== null) {
      const root = ReactDOM.createRoot(rootElement)
      root.render(
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      )
    } else {
      throw new Error('Cannot setup router with authentication because the rootElement is null')
    }
  })
  .catch(error => alert(error))
