import ErrorModal from './ErrorModal'
import { useErrorModal } from './ErrorModalContextProvider'
import IErrorModal from './IErrorModal'

function ErrorModalContainer (): JSX.Element {
  const { errors } = useErrorModal()

  return (
        <>
        { errors.map((error: IErrorModal) => <ErrorModal data-testid='error-modal-container-item' messageContent={error.messageContent} key={`${new Date().toString()}`} />)}
        </>
  )
}

export default ErrorModalContainer
