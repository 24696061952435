import { Button, Empty, Input, Modal, Table, TablePaginationConfig } from 'antd'
import { ChangeEvent, useEffect, useState } from 'react'
import { getShareCertificateTableColumns } from './models/ShareCertificateTableColumns'
import { SearchOutlined } from '@ant-design/icons'
import { useDebounce } from '@/hooks/UseDebounce'
import './ShareCertificateModal.less'
import { GetInviteOperatorCertificateOptionsDocument, GetRequestedCertificatesDocument, OrderBy, useGetInviteOperatorCertificateOptionsQuery, useRemoveCertificateInquiryMutation, useShareCertificateMutation } from '@/models/generated/graphql'
import mapInviteOperatorOptionsQuery from './ShareCertificateModalTableMapper'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import DataRow from '@/features/certificate/inquiry/components/shareCertificateModal/models/ShareCertificateTableDataRow'
import { useTranslation } from 'react-i18next'
import { debouncedTime } from '@/index'
import { useCurrentOperatorIdentifier } from '@/hooks/UseCurrentOperatorIdentifier'
import { useErrorModal } from '@/features/errorModal/ErrorModalContextProvider'

function ShareCertificateModal (): JSX.Element {
  const { t } = useTranslation()
  const { showError } = useErrorModal()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [totalCount, setTotalCount] = useState(-1)
  const [pageSize] = useState(8)
  const [debouncedSearch, search, setSearch] = useDebounce('', debouncedTime)
  const [offset, setOffset] = useState(0)
  const [orderBy, setOrderBy] = useState('status_skalNumber_asc')
  const [processingActions, setProcessingActions] = useState(false)
  const [currentOperatorIdentifier] = useCurrentOperatorIdentifier()

  const { loading, error, data, refetch } = useGetInviteOperatorCertificateOptionsQuery({
    variables: {
      limit: pageSize,
      offset,
      search: debouncedSearch,
      sort: orderBy,
      requestedOperatorId: currentOperatorIdentifier
    }
  })

  const [shareCertificateMutation] = useShareCertificateMutation({ refetchQueries: [GetInviteOperatorCertificateOptionsDocument, GetRequestedCertificatesDocument] })

  const [removeRequestingCertificateMutation] = useRemoveCertificateInquiryMutation({
    onCompleted: () => {
      // Check if the offset should be moved to the previous page
      if (offset > 0 && totalCount % pageSize === 1) {
        setOffset(offset - pageSize)
      }
    },
    refetchQueries: [GetInviteOperatorCertificateOptionsDocument, GetRequestedCertificatesDocument]
  })

  useEffect(() => {
    if (data != null) {
      setTotalCount(data.getInviteOperatorCertificateOptions?.count ?? 0)
    }
  }, [data])

  useEffect(() => {
    refetch({
      limit: pageSize,
      offset,
      search: debouncedSearch,
      sort: orderBy,
      requestedOperatorId: currentOperatorIdentifier
    }).catch(x => {
      console.error(x)
      showError()
    })
  }, [pageSize, offset, debouncedSearch, orderBy])

  if (error !== undefined) {
    console.error(error)
  }

  if (totalCount === -1 && data !== undefined) {
    setTotalCount(data.getInviteOperatorCertificateOptions?.count ?? 0)
  }

  const handleOnClickShareAction = (requestingOperatorId: string): void => {
    setProcessingActions(true)
    shareCertificateMutation({ variables: { requestedOperatorId: currentOperatorIdentifier, requestingOperatorId } })
      .then(() => { setProcessingActions(false) })
      .catch(x => {
        console.error(x)
        showError()
        setProcessingActions(false)
      })
  }

  const handleOnClickCancelShareAction = (id: string): void => {
    if (processingActions) { return }

    setProcessingActions(true)
    removeRequestingCertificateMutation({ variables: { id } })
      .then(() => { setProcessingActions(false) })
      .catch(x => {
        console.error(x)
        showError()
        setProcessingActions(false)
      })
  }
  const columns = getShareCertificateTableColumns({ handleOnClickShareAction, handleOnClickCancelShareAction })

  const dataSource = data != null ? mapInviteOperatorOptionsQuery(data) : []

  const handleTableChange = (_pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: SorterResult<DataRow> | Array<SorterResult<DataRow>>, _extra: TableCurrentDataSource<DataRow>): void => {
    const sorterArray = Array.isArray(sorter) ? sorter : [sorter]
    const orderByDict: { [name: string]: OrderBy } = {}
    for (const sort of Array.isArray(sorter) ? sorter : [sorter]) {
      if (sort.columnKey !== undefined) {
        orderByDict[sort.columnKey] = sort.order === 'ascend' ? OrderBy.Asc : OrderBy.Desc
      }
    }
    if (sorterArray.some(x => x.column !== undefined)) {
      setOrderBy(`${Object.keys(orderByDict)[0]}_${Object.values(orderByDict)[0]}`)
    }
    setOffset(((_pagination.current ?? 1) - 1) * pageSize)
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setOffset(0)
    setSearch(e.target.value)
  }

  const onShareCertificateButtonClicked = (): void => {
    setIsModalOpen(true)
    if (debouncedSearch !== '') {
      refetch({
        limit: pageSize,
        offset,
        search: debouncedSearch,
        sort: orderBy,
        requestedOperatorId: currentOperatorIdentifier
      }).catch(x => {
        console.error(x)
        showError()
      })
    }
  }

  const onCloseModalClicked = (): void => {
    setIsModalOpen(false)
  }

  const table = (): JSX.Element => {
    if (search !== '') {
      return (<Table className='share-certificate-table' data-testid="share-certificate-table"
        loading={loading || processingActions}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          position: ['bottomCenter'],
          pageSize,
          total: totalCount,
          current: (Math.floor(offset / pageSize)) + 1,
          showSizeChanger: false,
          size: 'default'
        }}
        onChange={handleTableChange}
        locale={{
          emptyText: <Empty description={t('certificates.inquiry.table.noSearchResults')} />,
          triggerAsc: t('certificates.inquiry.table.ascSort').toString(),
          triggerDesc: t('certificates.inquiry.table.descSort').toString(),
          cancelSort: t('certificates.inquiry.table.cancelSort').toString()
        }}
        scroll={{ x: 820 }}
      />)
    } else {
      return (<div className='empty-container'>
        <div className="empty-image" />
        <p className="grey-text-color">{t('certificates.inquiry.shareCertificate.table.searchText')}</p>
      </div>)
    }
  }

  const footer = (<div>
    <Button className='close-modal-button' onClick={onCloseModalClicked}>{t('certificates.inquiry.shareCertificate.closeModal')}</Button>
  </div>)

  return (<div className='share-certificate-modal-component'>
    <Button className='open-modal-button' data-testid="open-modal-button" onClick={onShareCertificateButtonClicked}>{t('certificates.inquiry.shareCertificate.modalTitle')}</Button>
    <Modal className='share-certificate-modal-wrapper' width="868px" title={t('certificates.inquiry.shareCertificate.modalTitle')} open={isModalOpen} footer={footer} onCancel={onCloseModalClicked} >
      <div className="share-certificate-modal">
        <Input className='share-certificate-modal-table-search' data-testid="share-certificate-modal-table-search"
          value={search}
          onChange={handleSearchChange}
          placeholder={t('certificates.inquiry.shareCertificate.table.searchText').toString()}
          allowClear
          addonAfter={<SearchOutlined />}
        />
        {table()}
      </div>
    </Modal>
  </div>
  )
}
export default ShareCertificateModal
