import '@/features/notFound/NotFound.less'
import { useTranslation } from 'react-i18next'

export default function NotFound (): JSX.Element {
  const { t } = useTranslation()

  return (
    <div className="not-found-container" data-testid="notfound-testid">
      <h1>{t('notFound.title')}</h1>
      <p>{t('notFound.subtitle')}</p>
      <div className="not-found-image-container">
        <div className="not-found-image"></div>
      </div>
    </div>
  )
}
