import Keycloak from 'keycloak-js'
import IAuthenticationService from '@/features/authentication/IAuthenticationService'
import i18n from 'i18next'

export default class AuthenticationService implements IAuthenticationService {
  // eslint-disable-next-line
  private readonly _keycloak: Keycloak
  private readonly _minValidity = 180

  get isAuthenticated (): boolean {
    return this._keycloak.authenticated !== undefined ? this._keycloak.authenticated : false
  }

  get token (): string {
    return this._keycloak.token !== undefined ? this._keycloak.token : ''
  }

  get tokenParsed (): Record<string, unknown> {
    return Object(this._keycloak.tokenParsed)
  }

  get username (): string {
    return this.tokenParsed.name as string
  }

  // eslint-disable-next-line
  constructor (client?: Keycloak) {
    this._keycloak = client ?? new Keycloak({ realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '', url: process.env.REACT_APP_KEYCLOAK_URL, clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '' })
  }

  async authenticateAsync (redirectUri?: string): Promise<void> {
    try {
      redirectUri = redirectUri !== undefined ? redirectUri : window.location.href
      await this._keycloak.init({ onLoad: 'login-required', redirectUri, checkLoginIframe: false })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async refreshTokenAsync (): Promise<void> {
    try {
      await this._keycloak.updateToken(this._minValidity)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async logoutAsync (): Promise<void> {
    try {
      const languageQueryParam = 'language'
      const redirectUrlString = process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL ?? ''
      let keycloakLogoutOptions = {}
      if (redirectUrlString !== '') {
        const redirectUrl = new URL(redirectUrlString)
        redirectUrl.searchParams.set(languageQueryParam, i18n.language)
        keycloakLogoutOptions = {
          redirectUri: redirectUrl
        }
      }
      await this._keycloak.logout(keycloakLogoutOptions)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
