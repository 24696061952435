import React from 'react'
import IAuthenticationService from '@/features/authentication/IAuthenticationService'
import AuthenticationService from '@/features/authentication/AuthenticationService'
import AuthenticationProviderProps from '@/features/authentication/models/AuthenticationProviderProps'

const AuthenticationContext = React.createContext<IAuthenticationService>(new AuthenticationService())

function AuthenticationProvider (props: AuthenticationProviderProps): JSX.Element {
  const { children, authenticationService } = props

  return (
    <AuthenticationContext.Provider value={authenticationService}>
        {children}
    </AuthenticationContext.Provider>
  )
}

const useAuthenticationService = (): IAuthenticationService => React.useContext<IAuthenticationService>(AuthenticationContext)

export { AuthenticationProvider, useAuthenticationService }
