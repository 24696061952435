import '@/features/certificate/inquiry/components/certificateInquiryRequestedTable/models/CertificateInquiryRequestedTableColums.less'
import { t } from 'i18next'
import { Location } from '@/models/generated/graphql'
import CertificateInquiryStatus from '@/models/enums/CertificateInquiryStatus'
import DataRow from '@/features/certificate/inquiry/components/certificateInquiryRequestedTable/models/CertificateInquiryRequestedTableDataRow'
import Actions from '@/features/certificate/inquiry/components/certificateInquiryRequestedTable/models/CertificateInquiryRequestedTableActions'

const status = (status: CertificateInquiryStatus): string => {
  if (status === CertificateInquiryStatus.Accepted) {
    return t('certificates.inquiry.table.data.status.accepted')
  } else {
    return t('certificates.inquiry.table.data.status.new')
  }
}

const action = (actions: Actions, status: CertificateInquiryStatus, row: DataRow): JSX.Element => {
  if (status === CertificateInquiryStatus.Accepted) {
    return <a data-testid="withdraw" onClick={() => actions.handleOnClickWithdrawAction(row.id)}>{t('certificates.inquiry.table.data.actions.withdraw')}</a>
  } else {
    return (
    <div className='certificate-inquiry-requested-table-colums-actions'>
      <a data-testid="accept" onClick={() => actions.handleOnClickAcceptAction(row.id)}>{t('certificates.inquiry.table.data.actions.accept')}</a>
      <div className="certificate-inquiry-requested-table-colums-divider-container">
        <span className="certificate-inquiry-requested-table-colums-divider"></span>
      </div>
      <a data-testid="decline" onClick={() => actions.handleOnClickDeclineAction(row.id)}>{t('certificates.inquiry.table.data.actions.decline')}</a>
    </div>)
  }
}

export const getTableColumns = (actions: Actions): any => {
  return [
    {
      key: 'name',
      title: t('certificates.inquiry.table.columns.name'),
      dataIndex: 'name',
      render: (x: string) => x,
      width: 285,
      ellipsis: true,
      sorter: true
    }, {
      key: 'skalNumber',
      title: t('certificates.inquiry.table.columns.skalNumber'),
      dataIndex: 'skalNumber',
      sorter: true,
      width: 135
    }, {
      key: 'primaryLocation',
      title: t('certificates.inquiry.table.columns.primaryLocation'),
      dataIndex: 'primaryLocation',
      render: (x: Location) => `${x?.street ?? ''} ${x?.houseNumber ?? ''}, ${x?.postalCode as string ?? ''}, ${x?.city ?? ''}`,
      ellipsis: true,
      width: 200
    }, {
      key: 'sharedSince',
      title: t('certificates.inquiry.table.columns.sharedSince'),
      dataIndex: 'sharedSince',
      render: (x: string, row: DataRow) => row.status === CertificateInquiryStatus.Accepted ? new Date(x).toLocaleDateString() : '-',
      width: 125
    }, {
      key: 'status',
      title: t('certificates.inquiry.table.columns.status'),
      dataIndex: 'status',
      render: (x: CertificateInquiryStatus) => status(x),
      width: 125
    }, {
      key: 'actions',
      title: t('certificates.inquiry.table.columns.actions'),
      dataIndex: 'actions',
      render: (x: CertificateInquiryStatus, row: DataRow) => (<div>{ action(actions, x, row) }</div>),
      width: 200
    }
  ]
}
