import '@/features/header/profile/MySkalProfile.less'
import { Button, Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'
import { useAuthenticationService } from '@/features/authentication/AuthenticationContextProvider'
import { UserOutlined, LogoutOutlined, GlobalOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { useGetOperatorBySkalNumberQuery } from '@/models'
import { useMatches, useNavigate } from 'react-router-dom'
import { translateRouteAsync } from '@/router/routerTranslator'
import { useErrorModal } from '@/features/errorModal/ErrorModalContextProvider'

function MySkalProfile (): JSX.Element {
  const { t, i18n } = useTranslation()
  const { showError } = useErrorModal()
  const authenticationService = useAuthenticationService()
  const matches = useMatches()
  const navigate = useNavigate()

  const query = new URLSearchParams(window.location.search)
  const skalNumber = query.get('skalNumber') ?? ''
  const { data } = useGetOperatorBySkalNumberQuery({ variables: { skalNumber } })
  const operator = data?.operator[0]

  const showBigLanguageButton = useMediaQuery({ minWidth: 1256 })
  const showBigProfileButton = useMediaQuery({ minWidth: 914 })

  const onLogoutClicked = (e: any): void => {
    authenticationService.logoutAsync()
      .catch(err => {
        console.error(err)
        showError()
      })
  }

  // Create all items for the profile dropdown
  const profileMenuItems: MenuProps['items'] = [
    {
      label: t('menu.logout'),
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: onLogoutClicked
    }
  ]

  // Create all items for the languages dropdown
  const languageMenuItems: MenuProps['items'] = []
  Object.keys(i18n.services.resourceStore.data).forEach(x => {
    languageMenuItems.push({
      key: x,
      label: <span data-test-id="language-option">{t('language', { lng: x })} </span>
    })
  })

  const handleLanguageMenuItemClicked = (e: any): void => {
    handleLanguageMenuItemClickedAsync(e)  // eslint-disable-line
  }
  const handleLanguageMenuItemClickedAsync = async (e: any): Promise<void> => {
    await translateRouteAsync(window.location.pathname, matches, e, navigate)
  }

  return (
    <div className="profile" data-testid="my-skal-profile">
      <span className="operator-name">{operator?.locations.find(x => x.isPrimary)?.tradeName}</span>

      <Dropdown className="language" menu={{ items: languageMenuItems, onClick: handleLanguageMenuItemClicked }} placement="bottomRight">
        <Button data-testid='language-dropdown' icon={!showBigLanguageButton ? <GlobalOutlined /> : undefined} >{showBigLanguageButton && t('language')}</Button>
      </Dropdown>

      {showBigProfileButton &&
        <Dropdown.Button menu={{ items: profileMenuItems }} placement="bottomRight" icon={<UserOutlined />} data-testid='profile-dropdown-big'>
          {authenticationService.username}
        </Dropdown.Button>
      }
      {!showBigProfileButton &&
        <Dropdown menu={{ items: profileMenuItems }} placement="bottomRight" data-testid='profile-dropdown-small'>
          <Button className="profile-button" data-testid='profile-dropdown-small-button' icon={<UserOutlined />} ></Button>
        </Dropdown>
      }
    </div>
  )
}

export default MySkalProfile
