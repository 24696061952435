import '@/features/certificate/inquiry/components/certificateInquiryTabs/CertificateInquiryTabs.less'
import { useTranslation } from 'react-i18next'
import { Button, Badge, Tooltip } from 'antd'
import { useState } from 'react'
import Props from '@/features/certificate/inquiry/components/certificateInquiryTabs/models/Props'
import classNames from 'classnames'
import { useCurrentOperatorIdentifier } from '@/hooks/UseCurrentOperatorIdentifier'
import { useGetPendingRequestedCertificateCountQuery } from '@/models'

function CertificateInquiryTabs ({ onClickRequesting, onClickRequested, hasActiveCertificate }: Readonly<Props>): JSX.Element {
  const { t } = useTranslation()

  const [requesting, setRequesting] = useState(true)
  const [currentOperatorIdentifier] = useCurrentOperatorIdentifier()

  const handleOnClickRequesting = (_: React.MouseEvent<HTMLButtonElement>): void => {
    setRequesting(true)
    onClickRequesting()
  }

  const handleonClickRequested = (_: React.MouseEvent<HTMLButtonElement>): void => {
    setRequesting(false)
    onClickRequested()
  }

  const { data } = useGetPendingRequestedCertificateCountQuery({ variables: { requested_operator_id: currentOperatorIdentifier } })

  return (
    <div className="certificate-inquiry-tabs-container">
      <Button type="text" onClick={handleOnClickRequesting} className={classNames('certificate-inquiry-tab', { active: requesting })}><p>{t('certificates.inquiry.tabs.requestingOperator')}</p></Button>
      {hasActiveCertificate &&
        <Button type="text" onClick={handleonClickRequested} className={classNames('certificate-inquiry-tab', { active: !requesting })} data-testid='share-certificate-enabled'>
          <p>
            {t('certificates.inquiry.tabs.requestedOperator')}
            <Badge count={data?.operator_certificate_inquiry_aggregate.aggregate?.count} offset={[5, -2]} />
          </p>
        </Button>
      }
      {!hasActiveCertificate &&
        <Tooltip title={t('certificates.inquiry.tabs.requestedOperatorDisabledTooltip')}>
          <Button type="text" disabled className={classNames('certificate-inquiry-tab', { active: !requesting, disabled: !hasActiveCertificate })} data-testid='share-certificate-disabled'>
            <p>
              {t('certificates.inquiry.tabs.requestedOperator')}
            </p>
          </Button>
        </Tooltip>
      }
    </div>
  )
}

export default CertificateInquiryTabs
