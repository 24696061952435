import { NavigateFunction, Params } from 'react-router-dom'
import { multiLanguageRoutes, pathKey, routeKey } from './routes'
import i18n from 'i18next'

type matches = Array<{
  id: string
  pathname: string
  params: Params<string>
  data: unknown
  handle: unknown
}>

export const translateRouteAsync = async (currentRoute: string, matches: matches, e: any, navigate: NavigateFunction): Promise<void> => {
  const params = matches.find(x => Object.keys(x.params).length > 0)?.params
  const paramKeys = Object.keys(params ?? [])
  const paramValue = Object.values(params ?? [])

  // replace param value with placeholder
  paramKeys.forEach((x, index) => {
    const value = paramValue[index] ?? ''
    currentRoute = currentRoute.replace(value, `:${x}`)
  })

  // find current route key
  let currentRouteKey
  switch (i18n.language) {
    case 'en':
      currentRouteKey = Object.keys(multiLanguageRoutes).find(routeName => multiLanguageRoutes[routeName as routeKey].paths.en === currentRoute)
      break
    case 'de':
      currentRouteKey = Object.keys(multiLanguageRoutes).find(routeName => multiLanguageRoutes[routeName as routeKey].paths.de === currentRoute)
      break
    default:
      currentRouteKey = Object.keys(multiLanguageRoutes).find(routeName => multiLanguageRoutes[routeName as routeKey].paths.nl === currentRoute)
      break
  }

  // change to selected language
  await i18n.changeLanguage(e.key)
    .catch(err => console.error(err))

  // set navigateToUrl to url in new language based on current route key
  let navigateToUrl = currentRouteKey !== undefined
    ? multiLanguageRoutes[currentRouteKey as routeKey].paths[i18n.language as pathKey]
    : multiLanguageRoutes.notFound.paths[i18n.language as pathKey]

  // replace param placeholder with actual value
  paramKeys.forEach((x, index) => {
    const value = paramValue[index] ?? ''
    navigateToUrl = navigateToUrl.replace(`:${x}`, value)
  })

  navigate(navigateToUrl)
}
