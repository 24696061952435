import { useEffect, useState } from 'react'

export function useCurrentOperatorIdentifier (): [string, React.Dispatch<string>] {
  const currentOperatorIdentifierKey = 'operatorId'

  const [currentOperatorIdentifier, setCurrentOperatorIdentifier] = useState<string>(() => {
    return localStorage.getItem(currentOperatorIdentifierKey) ?? ''
  })

  useEffect(() => {
    if (currentOperatorIdentifier === null) {
      throw new Error('Current operator identifier cannot be null')
    }
    localStorage.setItem(currentOperatorIdentifierKey, currentOperatorIdentifier)
  }, [currentOperatorIdentifier])

  return [currentOperatorIdentifier, setCurrentOperatorIdentifier]
}
